import { useCallback, useContext } from 'react';
import { useStore } from 'zustand';
import {
  PromptInputs,
  useIdeationStore,
} from '@studio/features/projects/stores';
import { ProjectStoreContext } from '@studio/providers';
import { useParamStore } from '@studio/stores';
import { ProjectElementType } from '@lib/types';

export function useBrainstorm(type: ProjectElementType) {
  const { startNewPrompt } = useIdeationStore();
  const { setParams } = useParamStore();
  const { projectStore } = useContext(ProjectStoreContext);
  const { primaryTitle, primaryConcept, primaryThumbnail } = useStore(
    projectStore,
    (store) => {
      return {
        primaryConcept: store.getPrimary('concept'),
        primaryTitle: store.getPrimary('title'),
        primaryThumbnail: store.getPrimary('thumbnail'),
      };
    }
  );
  const startBrainstorm = useCallback(
    ({
      input,
      newType,
      index,
    }: {
      input: PromptInputs;
      newType?: ProjectElementType;
      index?: number;
    }) => {
      const projectInput = {
        primaryTitle,
        primaryConcept,
        primaryThumbnail,
      };
      startNewPrompt({
        input,
        type: newType || type,
        index,
        projectInput,
      });
      setParams({
        element: newType || type,
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [primaryTitle, primaryConcept, primaryThumbnail]
  );

  return { startBrainstorm };
}
