import { useQuery } from '@tanstack/react-query';
import { fetchSubscriberCount } from '../requests';

export function useFetchSubscriberCountQuery(input: { channelUcid: string }) {
  return useQuery({
    queryKey: ['fetch-subscriber-count', input.channelUcid],
    queryFn: () => fetchSubscriberCount(input),
    enabled: Boolean(input?.channelUcid),
  });
}
