export const projects_click = 'projects_click';
export const projects_projects_status_filter_open =
  'projects_projects_status_filter_open';
export const projects_projects_status_filter_change =
  'projects_projects_status_filter_change';
export const projects_access_filter_open = 'projects_access_filter_open';
export const projects_access_filter_change = 'projects_access_filter_change';
export const projects_search_filter_change = 'projects_search_filter_change';
export const projects_search_filter_reset = 'projects_search_filter_reset';
export const projects_sort_click = 'projects_sort_click';
export const projects_sort_last_edited_oldest_to_newest_click =
  'projects_sort_last_edited_oldest_to_newest_click';
export const projects_sort_last_edited_newest_to_oldest_click =
  'projects_sort_last_edited_newest_to_oldest_click';
export const projects_sort_publish_date_oldest_to_newest_click =
  'projects_sort_publish_date_oldest_to_newest_click';
export const projects_sort_publish_date_newest_to_oldest_click =
  'projects_sort_publish_date_newest_to_oldest_click';
export const projects_sort_created_date_oldest_to_newest_click =
  'projects_sort_created_date_oldest_to_newest_click';
export const projects_sort_created_date_newest_to_oldest_click =
  'projects_sort_created_date_newest_to_oldest_click';
export const projects_sort_title_A_Z_click = 'projects_sort_title_A_Z_click';
export const projects_sort_title_Z_A_click = 'projects_sort_title_Z_A_click';
export const projects_new_projects_click_from_top_right =
  'projects_new_projects_click_from_top_right';
export const projects_new_projects_click_from_project_grid =
  'projects_new_projects_click_from_project_grid';
export const projects_delete_project_click = 'projects_delete_project_click';
export const projects_duplicate_project_click =
  'projects_duplicate_project_click';
export const projects_add_new_click_on_side = 'projects_add_new_click_on_side';
export const projects_copy_click_on_side = 'projects_copy_click_on_side';
export const projects_download_click_on_side =
  'projects_download_click_on_side';
export const projects_delete_click_on_side = 'projects_delete_click_on_side';
export const projects_thumbnail_upload_click =
  'projects_thumbnail_upload_click';
export const projects_thumbnail_upload_success =
  'projects_thumbnail_upload_success';
export const projects_remove_from_saved_item_click =
  'projects_remove_from_saved_item_click';
export const projects_save_for_later_click = 'projects_save_for_later_click';
export const projects_channel_link_to_youtube_click =
  'projects_channel_link_to_youtube_click';
export const projects_AI_settings_click = 'projects_AI_settings_click';
export const projects_less_more_toggle = 'projects_less_more_toggle';
export const projects_view_project_click = 'projects_view_project_click';
export const projects_concept_saved = 'projects_concept_saved';
export const projects_title_saved = 'projects_title_saved';
export const projects_thumbnail_saved = 'projects_thumbnail_saved';
export const projects_properties_shared_type_toggle =
  'projects_properties_shared_type_toggle';
export const projects_properties_project_status_open =
  'projects_properties_project_status_open';
export const projects_properties_project_status_change =
  'projects_properties_project_status_change';
export const projects_properties_target_date_reset =
  'projects_properties_target_date_reset';
export const projects_properties_target_video_length_reset =
  'projects_properties_target_video_length_reset';
export const projects_properties_target_date_change =
  'projects_properties_target_date_change';
export const projects_properties_target_video_length_change =
  'projects_properties_target_video_length_change';
export const projects_properties_video_type_open =
  'projects_properties_video_type_open';
export const projects_properties_video_type_change =
  'projects_properties_video_type_change';
export const projects_properties_sponsor_reset =
  'projects_properties_sponsor_reset';
export const projects_properties_sponsor_change =
  'projects_properties_sponsor_change';
export const projects_properties_notepad_change =
  'projects_properties_notepad_change';
export const projects_brainstorm_click = 'projects_brainstorm_click';
export const projects_brainstorm_more_click = 'projects_brainstorm_more_click';
export const projects_brainstorm_change_click =
  'projects_brainstorm_change_click';
export const projects_brainstorm_back_to_project_click =
  'projects_brainstorm_back_to_project_click';
export const projects_brainstorm_backups_click =
  'projects_brainstorm_backups_click';
export const projects_properties_youtube_url_reset =
  'projects_properties_youtube_url_reset';
export const projects_properties_youtube_url_change =
  'projects_properties_youtube_url_change';
export const projects_properties_publish_date_change =
  'projects_properties_publish_date_change';
export const projects_properties_publish_date_reset =
  'projects_properties_publish_date_reset';
export const projects_brainstorm_setting_click =
  'projects_brainstorm_setting_click';
export const projects_brainstorm_backups_element_click =
  'projects_brainstorm_backups_element_click';
export const projects_brainstorm_setting_input_change =
  'projects_brainstorm_setting_input_change';
export const projects_brainstorm_setting_inspired_by_change =
  'projects_brainstorm_setting_inspired_by_change';
export const projects_brainstorm_setting_thumbnail_style_change =
  'projects_brainstorm_setting_thumbnail_style_change';
export const projects_brainstorm_setting_audience_description_change =
  'projects_brainstorm_setting_audience_description_change';
export const projects_brainstorm_setting_additional_detail_change =
  'projects_brainstorm_setting_additional_detail_change';
export const projects_brainstorm_setting_generate_click =
  'projects_brainstorm_setting_generate_click';
export const projects_brainstorm_next_page_click =
  'projects_brainstorm_next_page_click';
export const projects_brainstorm_previous_page_click =
  'projects_brainstorm_previous_page_click';
export const projects_brainstorm_copy_click = 'projects_brainstorm_copy_click';
export const projects_brainstorm_keep_click = 'projects_brainstorm_keep_click';
export const projects_brainstorm_continue_click =
  'projects_brainstorm_continue_click';
export const projects_brainstorm_edit_textbox_change =
  'projects_brainstorm_edit_textbox_change';
export const projects_brainstorm_setting_input_current_options_thumbnail_upload_click =
  'projects_brainstorm_setting_input_current_options_thumbnail_upload_click';
export const projects_brainstorm_setting_input_current_options_thumbnail_upload_success =
  'projects_brainstorm_setting_input_current_options_thumbnail_upload_success';
export const projects_brainstorm_setting_input_current_options_thumbnail_download_click =
  'projects_brainstorm_setting_input_current_options_thumbnail_download_click';
export const projects_brainstorm_setting_input_current_options_thumbnail_delete_click =
  'projects_brainstorm_setting_input_current_options_thumbnail_delete_click';
export const projects_brainstorm_setting_input_current_options_title_change =
  'projects_brainstorm_setting_input_current_options_title_change';
export const projects_brainstorm_setting_input_current_options_concept_change =
  'projects_brainstorm_setting_input_current_options_concept_change';
export const projects_published_status_toggle =
  'projects_published_status_toggle';
