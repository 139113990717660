import { useTranslation } from 'react-i18next';
import {
  projects_access_filter_change,
  projects_access_filter_open,
} from '@studio/heap/events';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';
import { Icons, Select } from '@lib/ui';
import { ACCESS, PARAM_KEYS } from '../../constants';

const ScopeFilterItem = ({
  children,
  ...props
}: {
  children: string;
  value: string;
}) => {
  return (
    <Select.Item {...props}>
      <Select.ItemIndicator>
        <Icons.CheckmarkIcon aria-hidden />
      </Select.ItemIndicator>
      <Select.ItemText>{children}</Select.ItemText>
    </Select.Item>
  );
};

export function ScopeFilter() {
  const { t } = useTranslation();
  const { params, setParams } = useParamStore();

  const scopeValue = params[PARAM_KEYS.ACCESS] || ACCESS.ALL;

  const items = [
    {
      label: t('All'),
      value: ACCESS.ALL,
    },
    {
      label: t('Private'),
      value: ACCESS.USER_OWNED,
    },
    {
      label: t('Team'),
      value: ACCESS.SHARED,
    },
  ];

  const handleChange = (value: string) => {
    trackEvent(projects_access_filter_change, {
      old_value: scopeValue,
      new_value: value,
    });
    setParams({ [PARAM_KEYS.ACCESS]: value });
  };

  return (
    <Select.Root
      value={scopeValue}
      onValueChange={handleChange}
      onOpenChange={(value) => {
        if (value) {
          trackEvent(projects_access_filter_open);
        }
      }}
    >
      <Select.Trigger label={items[0].label} variant="subtle" size="sm">
        {t('Access')}: <Select.Value placeholder={items[0].label} />
        <Select.SelectIcon />
      </Select.Trigger>
      <Select.Portal>
        <Select.Content>
          <Select.Viewport>
            <Select.Group>
              {items.map((item) => (
                <ScopeFilterItem
                  key={item.value}
                  children={t(item.label)}
                  {...item}
                />
              ))}
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
