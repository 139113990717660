import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function Header1Icon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.01716 7.32373H1.008V25.6666H4.01716V17.7119H12.5213V25.6666H15.5305V7.32373H12.5213V15.0429H4.01716V7.32373ZM26.746 7.3499H23.92V9.36473C23.92 10.3067 23.5013 11.0656 22.5855 11.0656H19.7071V13.5252H22.7163C23.2135 13.5252 23.606 13.4467 23.8676 13.2897V25.6666H26.746V7.3499Z"
        fill="currentColor"
      />
    </svg>
  );
}
