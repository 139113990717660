import {
  projects_brainstorm_click,
  projects_delete_project_click,
  projects_duplicate_project_click,
  projects_add_new_click_on_side,
  projects_copy_click_on_side,
  projects_delete_click_on_side,
  projects_download_click_on_side,
  projects_thumbnail_upload_click,
  projects_thumbnail_upload_success,
} from '@studio/heap/events';
import { trackEvent } from '@studio/lib/heap';
import { ProjectElementType } from '@lib/types';

export const useProjectPackageAnalytics = (id: string) => {
  const track = (
    label: string,
    properties?: Record<string, string | undefined>
  ) => {
    trackEvent(label, {
      videoProject: id,
      ...properties,
    });
  };

  const onBrainstormEvent = (
    properties?: Record<string, string | undefined>
  ) => {
    track(projects_brainstorm_click, properties);
  };

  const onDeleteProject = () => {
    track(projects_delete_project_click);
  };

  const onProjectDuplicate = () => {
    track(projects_duplicate_project_click);
  };

  const onCreateElement = (element: ProjectElementType) => {
    track(projects_add_new_click_on_side, {
      element,
    });
  };

  const onDeleteElement = (element: ProjectElementType) => {
    track(projects_delete_click_on_side, {
      element,
    });
  };

  const onCopyElement = (element: ProjectElementType) => {
    track(projects_copy_click_on_side, {
      element,
    });
  };

  const onUploadClick = () => {
    track(projects_thumbnail_upload_click);
  };

  const onUploadSuccess = () => {
    track(projects_thumbnail_upload_success);
  };

  const onThumbnailDownload = () => {
    track(projects_download_click_on_side);
  };

  return {
    onBrainstormEvent,
    onDeleteProject,
    onProjectDuplicate,
    onCreateElement,
    onDeleteElement,
    onCopyElement,
    onUploadClick,
    onUploadSuccess,
    onThumbnailDownload,
  };
};
