import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function InfoIcon(props: IconProps) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3125 5.5625L5.3367 5.5504C5.67102 5.38324 6.04745 5.6852 5.9568 6.04782L5.5432 7.70218C5.45255 8.0648 5.82898 8.36676 6.1633 8.1996L6.1875 8.1875M11 6C11 8.89949 8.64949 11.25 5.75 11.25C2.85051 11.25 0.5 8.89949 0.5 6C0.5 3.10051 2.85051 0.75 5.75 0.75C8.64949 0.75 11 3.10051 11 6ZM5.75 3.8125H5.75437V3.81687H5.75V3.8125Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
