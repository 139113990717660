import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function DownloadIcon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 3.01685L14.5 19.0056L8.13299 12.5791C7.54994 11.9906 6.6002 11.9862 6.01169 12.5692C5.42319 13.1523 5.41877 14.102 6.00183 14.6905L15.0187 23.7917C15.3004 24.076 15.684 24.2359 16.0843 24.2359C16.4845 24.2359 16.8681 24.076 17.1498 23.7917L26.1667 14.6905C26.7498 14.102 26.7453 13.1523 26.1568 12.5692C25.5683 11.9862 24.6186 11.9906 24.0355 12.5791L17.5 19.1757L17.5 3.01685C17.5 2.18842 16.8284 1.51685 16 1.51685C15.1716 1.51685 14.5 2.18842 14.5 3.01685ZM1.01685 28.9719C1.01685 29.3697 1.17488 29.7513 1.45619 30.0326C1.73749 30.3139 2.11902 30.4719 2.51685 30.4719H29.4831C30.3116 30.4719 30.9831 29.8003 30.9831 28.9719V24.5899C30.9831 23.7614 30.3116 23.0899 29.4831 23.0899C28.6547 23.0899 27.9831 23.7614 27.9831 24.5899V27.4719H4.01685L4.01685 24.5899C4.01685 23.7614 3.34527 23.0899 2.51685 23.0899C1.68842 23.0899 1.01685 23.7614 1.01685 24.5899V28.9719Z"
        fill="currentColor"
      />
    </svg>
  );
}
