import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function LinkIcon(props: IconProps) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5 21.5835C25.9889 21.5835 27.6892 20.5129 28.7173 19.085C29.6903 17.7335 30 16.1569 30 15.0835C30 12.5946 28.9294 10.8943 27.5015 9.8662C26.15 8.89317 24.5734 8.5835 23.5 8.5835L18.5 8.5835C17.6716 8.5835 17 9.25507 17 10.0835C17 10.9119 17.6716 11.5835 18.5 11.5835L23.5 11.5835C24.0933 11.5835 25.0166 11.7738 25.7485 12.3008C26.404 12.7727 27 13.5724 27 15.0835C27 15.6768 26.8097 16.6001 26.2827 17.332C25.8108 17.9875 25.0111 18.5835 23.5 18.5835H18.5C17.6716 18.5835 17 19.2551 17 20.0835C17 20.9119 17.6716 21.5835 18.5 21.5835L23.5 21.5835ZM1.2827 11.082C2.31079 9.65413 4.01112 8.5835 6.5 8.5835H11.5C12.3284 8.5835 13 9.25507 13 10.0835C13 10.9119 12.3284 11.5835 11.5 11.5835H6.5C4.98888 11.5835 4.18921 12.1795 3.7173 12.835C3.19033 13.5669 3 14.4902 3 15.0835C3 16.5946 3.59603 17.3943 4.25146 17.8662C4.98336 18.3932 5.9067 18.5835 6.5 18.5835H11.5C12.3284 18.5835 13 19.2551 13 20.0835C13 20.9119 12.3284 21.5835 11.5 21.5835H6.5C5.42663 21.5835 3.84997 21.2738 2.49854 20.3008C1.07063 19.2727 0 17.5724 0 15.0835C0 14.0101 0.309671 12.4335 1.2827 11.082ZM6.5 13.5002H23.5C24.3284 13.5002 25 14.1717 25 15.0002C25 15.8286 24.3284 16.5002 23.5 16.5002H6.5C5.67157 16.5002 5 15.8286 5 15.0002C5 14.1717 5.67157 13.5002 6.5 13.5002Z"
        fill="currentColor"
      />
    </svg>
  );
}
