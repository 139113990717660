import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function CopyIcon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1864 4.51684C6.1864 4.2407 6.41026 4.01685 6.6864 4.01685H21.6568C22.4852 4.01685 23.1568 3.34527 23.1568 2.51685C23.1568 1.68842 22.4852 1.01685 21.6568 1.01685H6.6864C4.75341 1.01685 3.1864 2.58385 3.1864 4.51684V21.4775C3.1864 22.306 3.85797 22.9775 4.6864 22.9775C5.51483 22.9775 6.1864 22.306 6.1864 21.4775V4.51684ZM8.27193 27.9663C8.27193 29.6231 9.61507 30.9663 11.2719 30.9663H25.7939C27.4507 30.9663 28.7939 29.6231 28.7939 27.9663V9.25842C28.7939 7.60156 27.4507 6.25842 25.7939 6.25842H11.2719C9.61507 6.25842 8.27193 7.60156 8.27193 9.25842V27.9663ZM11.2719 9.25842V12.2584V24.9663V27.9663H14.2719H22.7939H25.7939V24.9663V12.2584V9.25842H22.7939H14.2719H11.2719Z"
        fill="currentColor"
      />
    </svg>
  );
}
