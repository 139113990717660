import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function Header2Icon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8881 17.3458L17.8493 23.338V25.6668H30.828V23.1287H21.9836L26.8506 19.0728C29.0748 17.215 30.7233 15.5142 30.7233 12.7928C30.7233 9.44349 28.3945 7.16699 24.2601 7.16699C20.3875 7.16699 17.8755 9.73133 17.8755 13.1853V13.6825H20.78V13.3423C20.78 11.092 22.193 9.65282 24.2601 9.65282C26.3796 9.65282 27.7665 10.8303 27.7665 12.9237C27.7665 14.6768 26.5628 15.959 24.8881 17.3458ZM4.01716 7.32399H1.008V25.6668H4.01716V17.7122H12.5213V25.6668H15.5305V7.32399H12.5213V15.0432H4.01716V7.32399Z"
        fill="currentColor"
      />
    </svg>
  );
}
