import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function ExpandIcon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9622 11.4949C27.9584 12.3233 28.6259 12.998 29.4532 13.0018C30.2805 13.0057 30.9543 12.3372 30.9582 11.5088L31 2.50698C31.0018 2.10674 30.8439 1.72235 30.5612 1.43934C30.2786 1.15633 29.8947 0.998155 29.495 1.00002L20.5052 1.04188C19.6779 1.04574 19.0103 1.72042 19.0142 2.54884C19.018 3.37726 19.6918 4.0457 20.5191 4.04185L26.0217 4.01622L18.8032 11.2443C18.2182 11.83 18.2182 12.7798 18.8032 13.3656C19.3883 13.9514 20.3367 13.9514 20.9217 13.3656L27.9864 6.29153L27.9622 11.4949ZM4.0378 20.5051C4.04165 19.6767 3.3741 19.002 2.54678 18.9982C1.71947 18.9943 1.04568 19.6628 1.04183 20.4912L1.00002 29.493C0.998157 29.8933 1.15612 30.2776 1.43875 30.5607C1.72139 30.8437 2.10526 31.0018 2.50497 31L11.4948 30.9581C12.3221 30.9543 12.9897 30.2796 12.9858 29.4512C12.982 28.6227 12.3082 27.9543 11.4809 27.9582L6.24061 27.9826L13.2997 20.914C13.8847 20.3282 13.8847 19.3785 13.2997 18.7927C12.7147 18.2069 11.7663 18.2069 11.1812 18.7927L4.01241 25.9711L4.0378 20.5051Z"
        fill="currentColor"
      />
    </svg>
  );
}
