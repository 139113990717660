import {
  ChangeEvent,
  forwardRef,
  Ref,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'zustand';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { ProjectSectionHeader } from '@studio/features/projects';
import { DELETE_THUMBNAIL_MODAL } from '@studio/features/projects/constants';
import {
  useCreateProjectMutation,
  useUploadThumbnailMutation,
} from '@studio/features/projects/hooks';
import {
  ProjectStateType,
  ProjectStoreContext,
  SetRoomProvider,
} from '@studio/providers';
import { useModalStore } from '@studio/stores';
import { useOrganizationStore } from '@studio/stores/organization.store';
import { downloadImage } from '@studio/utils';
import { VideoProjectMetaInput } from '@lib/gql/graphql';
import { PROJECT_ELEMENT } from '@lib/types';
import {
  ConfirmDialog,
  Icons,
  IdeationElement,
  MenuAction,
  Thumbnail,
  Toast,
} from '@lib/ui';
import * as Styles from './panel-elements.css';
import { useInspirationPanelAnalytics } from './use-inspriration-panel-analytics';

/**
 * Allowed image file types
 */
const imageMimeType = /image\/(jpg|jpeg|gif|png|webp)/i;

/**
 * Max image file size
 */
const imageMaxSize = 10 * 1024 * 1024; // 10MB

export const PanelThumbnail = forwardRef(
  (
    { onBrainstorm, ideating, ...props }: IdeationElement.IdeationElementProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();

    const { hasCreatorDescriptions, hasCastMembersDisabled } =
      useOrganizationStore();

    const { projectStore } = useContext(ProjectStoreContext);
    const {
      id,
      meta,
      thumbnail,
      getPrimaryOption,
      deleteElement,
      updateElement,
    } = useStore(projectStore, (state: ProjectStateType) => ({
      id: state.id,
      thumbnail: state.thumbnail,
      meta: state.meta,
      getPrimaryOption: state.getPrimaryOption,
      updateElement: state.updateElement,
      deleteElement: state.deleteElement,
    }));

    const {
      onUploadClick,
      onUploadSuccess,
      onThumbnailDownload,
      onThumbailDelete,
    } = useInspirationPanelAnalytics();

    const { setCreated, created } = useContext(SetRoomProvider);

    const type = PROJECT_ELEMENT.THUMBNAIL;

    const { modals, closeModal, openModal } = useModalStore();

    const handleOpenChange = (isOpen: boolean) => {
      isOpen
        ? openModal(DELETE_THUMBNAIL_MODAL)
        : closeModal(DELETE_THUMBNAIL_MODAL);
    };

    const primary = getPrimaryOption(type);

    const channelId = useActiveChannelUcid();
    const { activeOrganizationId: orgId, isSpotterOrgActive } =
      useOrganizationStore();

    const imageFileInputRef = useRef<HTMLInputElement>(null);

    const isCastMemberDisabled = hasCastMembersDisabled(channelId);
    const hasCastMembers = hasCreatorDescriptions(channelId);

    const hasCreatorImage =
      hasCastMembers || isCastMemberDisabled || isSpotterOrgActive();

    const hasThumbnail = primary?.content && primary?.content.trim().length > 0;

    const [isUploading, setIsUploading] = useState<boolean>(false);

    const openImageFileUploader = () => {
      onUploadClick();
      imageFileInputRef.current?.click();
    };

    const initiateThumbnailUpload = () =>
      !isUploading ? openImageFileUploader() : null;

    const createProject = useCreateProjectMutation();
    const postThumbnail = useUploadThumbnailMutation();
    const { toast } = Toast.useToast();

    const onInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        if (file && !file?.type?.match(imageMimeType)) {
          toast({
            icon: <Icons.ExclamationCircleIcon />,
            message: t(
              'Please upload an image in jpg, png, gif, or webp format'
            ),
          });

          return false;
        }
        if (file && file?.size > imageMaxSize) {
          toast({
            icon: <Icons.ExclamationCircleIcon />,
            message: t('Please upload an image no larger than 10MB'),
          });
          return false;
        }

        /**
         * File reader for previewing the image
         */
        const reader = new FileReader();
        reader.onload = (event) => {
          handleThumbnailUpload(event.target?.result as string, file.type);
        };
        reader.readAsDataURL(file);
      }
    };

    const resetThumbnail = () => {
      setIsUploading(false);
    };

    const syncUploadToStore = (image = '', id = '') => {
      updateElement('thumbnail', image, id);
    };

    const handleThumbnailUpload = useCallback(
      async (image: string, imageMimeType: string) => {
        const imageId = uuidv4();
        setIsUploading(true);
        let projectId = id;
        if (!created || !created.current) {
          setCreated(true);
          try {
            const project = await createProject.mutateAsync({
              channelId,
              orgId,
              meta: meta as VideoProjectMetaInput,
            });
            projectId = project.videoProjectId;
          } catch (err) {
            console.error(err);
            setCreated(false);
            toast({
              icon: <Icons.ExclamationCircleIcon aria-hidden />,
              message: t(
                'Failed to create a new project. Please try again later.'
              ),
            });
            return;
          }
        }
        postThumbnail.mutate(
          {
            image: image,
            imageFileName: `thumbnail-${imageId}`,
            imageMimeType: imageMimeType,
            projectId,
            channelId,
            orgId,
          },
          {
            onError: (error) => {
              if (error.message.includes('S3')) {
                console.error(error);

                toast({
                  icon: <Icons.ExclamationCircleIcon aria-hidden />,
                  message: t(
                    'Failed to upload Thumbnail. Please try again later.'
                  ),
                });
              } else {
                toast({
                  icon: <Icons.ExclamationCircleIcon aria-hidden />,
                  message: t('Thumbnail not added'),
                });
              }
              resetThumbnail();
            },
            onSuccess: (data) => {
              const { cloudfrontURL } = data;
              syncUploadToStore(cloudfrontURL, imageId);
              setIsUploading(false);
              onUploadSuccess();

              toast({
                icon: <Icons.CheckmarkFilledIcon aria-hidden />,
                message: t('Thumbnail added'),
              });
            },
          }
        );
      },
      [meta]
    );

    const handleDelete = () => {
      if (thumbnail.primary) {
        onThumbailDelete();
        deleteElement('thumbnail', thumbnail.primary);
      }
      closeModal(DELETE_THUMBNAIL_MODAL);
    };

    const initiateDelete = () => {
      openModal(DELETE_THUMBNAIL_MODAL);
    };

    const ACTIONS = MenuAction.MENU_ACTION;

    const actions = [
      {
        type: ACTIONS.DOWNLOAD,
        label: t('Download'),
        icon: <Icons.DownloadIcon aria-hidden />,
        handler: () => {
          onThumbnailDownload();
          downloadImage(primary?.content, primary?.id);
        },
      },
      {
        type: ACTIONS.DELETE,
        label: t('Delete'),
        icon: <Icons.DeleteIcon aria-hidden />,
        handler: initiateDelete,
      },
    ];

    if (!hasCreatorImage) {
      return null;
    }

    return (
      <div className="element-thumbnail" ref={ref}>
        <ProjectSectionHeader title={t('Thumbnail')} />
        <Thumbnail.Root
          isLoading={isUploading}
          className={Styles.thumbnailRoot}
        >
          {hasThumbnail ? (
            <>
              <Thumbnail.ImageViewer
                className={Styles.expandButton}
                icon={<Icons.ExpandIcon aria-label={t('Expand thumbnail')} />}
                src={primary?.content}
              />
              <IdeationElement.Actions
                className={Styles.thumbnailActions}
                actions={actions}
                align="end"
                alignOffset={-2}
                side="bottom"
              />
              <Thumbnail.Image src={primary?.content} />
            </>
          ) : (
            <div className={Styles.emptyThumb}>
              <Thumbnail.ImageUploadCard
                label={t('Upload')}
                onClick={initiateThumbnailUpload}
              />
              <Thumbnail.ImageUploader
                ref={imageFileInputRef}
                onChange={(event) => onInputChange(event)}
              />
            </div>
          )}
        </Thumbnail.Root>

        <ConfirmDialog
          cancelLabel={t('Cancel')}
          confirmLabel={t('Delete')}
          open={modals[DELETE_THUMBNAIL_MODAL]}
          onCancel={() => closeModal(DELETE_THUMBNAIL_MODAL)}
          onConfirm={handleDelete}
          onOpenChange={handleOpenChange}
          title={t('Delete thumbnail')}
        >
          {t(
            'Are you sure you want to delete this thumbnail? This action cannot be undone.'
          )}
        </ConfirmDialog>
      </div>
    );
  }
);
