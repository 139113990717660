import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { addYears, differenceInCalendarDays } from 'date-fns';
import { useStore } from 'zustand';
import { FilterDateInput } from '@studio/components';
import { ProjectStateType, ProjectStoreContext } from '@studio/providers';
import { PROJECT_META } from '@lib/types';
import { Label } from '@lib/ui';
import { STATUS } from '../../constants';
import * as Styles from './properties.css';
import { useProjectPropertiesAnalytics } from './use-project-properties-analytics';

const formatDaysAgo = (days: number) => {
  return Math.abs(days);
};

export const TargetDate = () => {
  const { projectStore } = useContext(ProjectStoreContext);
  const { status, publishDate, updateMeta } = useStore(
    projectStore,
    (state: ProjectStateType) => ({
      status: state.meta[PROJECT_META.STATUS],
      publishDate: state.meta[PROJECT_META.PUBLISH_DATE],
      updateMeta: state.updateMeta,
    })
  );

  const { onTargetDateChange, onTargetDateReset } =
    useProjectPropertiesAnalytics(projectStore.getState().id);

  const { t } = useTranslation();

  const handleTargetDateChange = (value: Date) => {
    onTargetDateChange(publishDateValue, value);
    updateMeta(
      PROJECT_META.PUBLISH_DATE,
      value ? value.toISOString() : undefined
    );
  };

  const today = new Date();

  const resetDateValue = () => {
    onTargetDateReset(publishDateValue);
    updateMeta(PROJECT_META.PUBLISH_DATE, undefined);
  };
  const publishDateValue =
    publishDate && typeof publishDate === 'string'
      ? new Date(publishDate)
      : undefined;

  const targetDateWarning = () => {
    if (!publishDate || status === STATUS.PUBLISHED) return undefined;
    const daysLeft = differenceInCalendarDays(publishDate, new Date());

    if (daysLeft === 0) {
      return t('Due today');
    }

    if (daysLeft === -1) {
      return t(`${formatDaysAgo(daysLeft)} day ago`);
    }

    if (daysLeft < 0) {
      return t(`${formatDaysAgo(daysLeft)} days ago`);
    }
    if (daysLeft === 1) {
      return t(`${daysLeft} day left`);
    }
    if (daysLeft <= 3) {
      return t(`${daysLeft} days left`);
    }
  };

  const warning = targetDateWarning();

  useEffect(() => {
    if (
      publishDateValue &&
      status !== STATUS.PUBLISHED &&
      publishDateValue < today
    ) {
      handleTargetDateChange(today);
    }
  }, [status]);

  return (
    <div id="target-date">
      <Label size="xxs">{t('Publish Date')}</Label>
      <div className={Styles.panelInputs}>
        <FilterDateInput
          id="project-target-date"
          error={warning}
          className={Styles.datePicker}
          value={publishDateValue}
          minDate={status === STATUS.PUBLISHED ? undefined : today}
          maxDate={addYears(new Date(), 10)}
          onClear={resetDateValue}
          onSelectDate={handleTargetDateChange}
        />
      </div>
    </div>
  );
};

TargetDate.displayName = 'TargetDate';
