import { useContext, useState } from 'react';
import {
  projects_brainstorm_setting_audience_description_change,
  projects_brainstorm_setting_input_change,
  projects_brainstorm_setting_inspired_by_change,
  projects_brainstorm_setting_thumbnail_style_change,
  projects_brainstorm_setting_additional_detail_change,
  projects_brainstorm_setting_generate_click,
  projects_brainstorm_setting_input_current_options_thumbnail_upload_click,
  projects_brainstorm_setting_input_current_options_thumbnail_upload_success,
  projects_brainstorm_setting_input_current_options_thumbnail_download_click,
  projects_brainstorm_setting_input_current_options_thumbnail_delete_click,
  projects_brainstorm_setting_input_current_options_title_change,
  projects_brainstorm_setting_input_current_options_concept_change,
} from '@studio/heap/events';
import { trackEvent } from '@studio/lib/heap';
import { ProjectStoreContext } from '@studio/providers';
import { ProjectElementType } from '@lib/types';
import { useInspirationStore } from '../../../brainstorm/stores';

export const useInspirationPanelAnalytics = () => {
  const { projectStore } = useContext(ProjectStoreContext);
  const { topic, temperature, audience, additional } = useInspirationStore();

  // Use to store previous value, for analytics
  const [localTopic, setLocalTopic] = useState(topic);
  const [localTemperature, setLocalTemperature] = useState(temperature);
  const [localAudience, setLocalAudience] = useState(audience);
  const [localAdditional, setLocalAdditional] = useState(additional);

  const track = (
    label: string,
    properties?: Record<string, string | undefined>
  ) => {
    trackEvent(label, {
      videoProject: projectStore.getState().id,
      ...properties,
    });
  };

  const onTopicBlur = () => {
    track(projects_brainstorm_setting_input_change, {
      old_value: localTopic,
      new_value: topic,
    });
    setLocalTopic(topic);
  };

  const onTemperatureBlur = () => {
    track(projects_brainstorm_setting_inspired_by_change, {
      old_value: `${localTemperature}`,
      new_value: `${temperature}`,
    });
    setLocalTemperature(temperature);
  };

  const onAudienceChange = (value: string) => {
    track(projects_brainstorm_setting_audience_description_change, {
      old_value: localAudience,
      new_value: value,
    });
    setLocalAudience(value);
  };

  const onThumbnailStyleChange = (old_value: string, new_value: string) =>
    track(projects_brainstorm_setting_thumbnail_style_change, {
      old_value,
      new_value,
    });

  const onAdditionalChange = (value: string) => {
    track(projects_brainstorm_setting_additional_detail_change, {
      old_value: localAdditional,
      new_value: value,
    });
    setLocalAdditional(value);
  };

  const onGenerate = (element: ProjectElementType) => {
    track(projects_brainstorm_setting_generate_click, {
      element,
    });
  };

  const onUploadClick = () => {
    track(
      projects_brainstorm_setting_input_current_options_thumbnail_upload_click
    );
  };

  const onUploadSuccess = () => {
    track(
      projects_brainstorm_setting_input_current_options_thumbnail_upload_success
    );
  };

  const onThumbnailDownload = () => {
    track(
      projects_brainstorm_setting_input_current_options_thumbnail_download_click
    );
  };

  const onThumbailDelete = () => {
    track(
      projects_brainstorm_setting_input_current_options_thumbnail_delete_click
    );
  };

  const onTitleChange = (oldValue: string, newValue: string) => {
    track(projects_brainstorm_setting_input_current_options_title_change, {
      old_value: oldValue,
      new_value: newValue,
    });
  };

  const onConceptChange = (oldValue: string, newValue: string) => {
    track(projects_brainstorm_setting_input_current_options_concept_change, {
      old_value: oldValue,
      new_value: newValue,
    });
  };

  return {
    onTopicBlur,
    onTemperatureBlur,
    onAudienceChange,
    onThumbnailStyleChange,
    onAdditionalChange,
    onGenerate,
    onUploadClick,
    onUploadSuccess,
    onThumbnailDownload,
    onThumbailDelete,
    onTitleChange,
    onConceptChange,
  };
};
