import { useState } from 'react';
import {
  projects_properties_shared_type_toggle,
  projects_properties_project_status_change,
  projects_properties_project_status_open,
  projects_properties_sponsor_change,
  projects_properties_sponsor_reset,
  projects_properties_publish_date_change,
  projects_properties_publish_date_reset,
  projects_properties_target_video_length_reset,
  projects_properties_target_video_length_change,
  projects_properties_video_type_change,
  projects_properties_video_type_open,
  projects_properties_youtube_url_change,
  projects_properties_youtube_url_reset,
} from '@studio/heap/events';
import { trackEvent } from '@studio/lib/heap';

export const useProjectPropertiesAnalytics = (
  id: string,
  {
    sponsors,
    targetDuration,
  }: { sponsors?: string[]; targetDuration?: string } = {}
) => {
  // Use to store previous value, for analytics
  const [localSponsorValue, setLocalSponsorValue] = useState(sponsors);
  const [localTargetDuration, setLocalTargetDuration] =
    useState(targetDuration);

  const track = (
    label: string,
    properties?: Record<string, string | undefined>
  ) => {
    trackEvent(label, {
      videoProject: id,
      ...properties,
    });
  };

  const onAccessChange = (oldValue: string, newValue: string) => {
    track(projects_properties_shared_type_toggle, {
      old_value: oldValue,
      new_value: newValue,
    });
  };

  const onProjectStatusOpen = () => {
    track(projects_properties_project_status_open);
  };

  const onProjectStatusChange = (oldValue: string, newValue: string) => {
    track(projects_properties_project_status_change, {
      old_value: oldValue,
      new_value: newValue,
    });
  };

  const onSponsorChange = (newValue: string[]) => {
    track(projects_properties_sponsor_change, {
      old_value: localSponsorValue?.join(', '),
      new_value: newValue.join(', '),
    });
    setLocalSponsorValue(sponsors);
  };

  const onSponsorReset = (oldValue: string[]) => {
    track(projects_properties_sponsor_reset, {
      old_value: oldValue.join(', '),
      new_value: '',
    });
  };

  const onTargetDateChange = (
    oldValue: Date | undefined,
    newValue: Date | undefined
  ) => {
    track(projects_properties_publish_date_change, {
      old_value: oldValue?.toISOString(),
      new_value: newValue?.toISOString(),
    });
  };

  const onTargetDateReset = (oldValue: Date | undefined) => {
    track(projects_properties_publish_date_reset, {
      old_value: oldValue?.toISOString(),
      new_value: '',
    });
  };

  const onVideoLengthChange = (newValue: string) => {
    track(projects_properties_target_video_length_change, {
      old_value: localTargetDuration,
      new_value: newValue,
    });
    setLocalTargetDuration(newValue);
  };

  const onVideoLengthReset = (oldValue: string) => {
    track(projects_properties_target_video_length_reset, {
      old_value: oldValue,
      new_value: '',
    });
  };

  const onVideoTypeOpen = () => {
    track(projects_properties_video_type_open);
  };

  const onVideoTypeChange = (oldValue: string, newValue: string) => {
    track(projects_properties_video_type_change, {
      old_value: oldValue,
      new_value: newValue,
    });
  };

  const onYTUrlChange = (oldValue: string, newValue: string) => {
    track(projects_properties_youtube_url_change, {
      old_value: oldValue,
      new_value: newValue,
    });
  };

  const onYTUrlReset = (oldValue: string) => {
    track(projects_properties_youtube_url_reset, {
      old_value: oldValue,
      new_value: '',
    });
  };

  return {
    onAccessChange,
    onProjectStatusOpen,
    onProjectStatusChange,
    onSponsorChange,
    onSponsorReset,
    onTargetDateChange,
    onTargetDateReset,
    onVideoLengthChange,
    onVideoLengthReset,
    onVideoTypeOpen,
    onVideoTypeChange,
    onYTUrlChange,
    onYTUrlReset,
  };
};
