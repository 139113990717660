import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function ImageIcon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6667 4H4.33333C4.14924 4 4 4.14924 4 4.33333V25.212L9.77267 19.4393C10.3585 18.8536 11.3082 18.8536 11.894 19.4393L14.6667 22.212L22.5227 14.356C23.1085 13.7702 24.0582 13.7702 24.644 14.356L28 17.712V4.33333C28 4.14924 27.8508 4 27.6667 4ZM27.6667 28H5.45465L10.8333 22.6213L13.606 25.394C14.1918 25.9798 15.1415 25.9798 15.7273 25.394L23.5833 17.538L28 21.9547V27.6667C28 27.8508 27.8508 28 27.6667 28ZM4.33333 1C2.49238 1 1 2.49238 1 4.33333V27.6667C1 29.5076 2.49238 31 4.33333 31H27.6667C29.5076 31 31 29.5076 31 27.6667V4.33333C31 2.49238 29.5076 1 27.6667 1H4.33333ZM9.83333 13.6667C11.6743 13.6667 13.1667 12.1743 13.1667 10.3333C13.1667 8.49238 11.6743 7 9.83333 7C7.99238 7 6.5 8.49238 6.5 10.3333C6.5 12.1743 7.99238 13.6667 9.83333 13.6667Z"
        fill="currentColor"
      />
    </svg>
  );
}
