import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function DeleteIcon(props: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9401 5.09623C12.9401 4.76381 13.4448 4.01685 14.5021 4.01685H17.5642C18.303 4.01685 18.6156 4.28162 18.7755 4.48109C18.8743 4.6042 18.9451 4.74422 18.9902 4.87743C19.0314 4.99908 19.0394 5.07977 19.0409 5.09457C19.0411 5.09712 19.0412 5.09772 19.0412 5.09623V6.74718H12.9401V5.09623ZM9.94008 6.74718V5.09623C9.94008 2.61478 12.3273 1.01685 14.5021 1.01685H17.5642C19.207 1.01685 20.3829 1.69002 21.1161 2.60439C21.791 3.44617 22.0412 4.42807 22.0412 5.09623V6.74718H28.6311C29.4595 6.74718 30.1311 7.41876 30.1311 8.24718C30.1311 9.07561 29.4595 9.74718 28.6311 9.74718H26.5918V26.1124C26.5918 27.018 26.309 28.1769 25.6096 29.1482C24.8676 30.1788 23.6559 30.9831 21.9738 30.9831H10.7659C9.68979 30.9831 8.33121 30.8239 7.20217 30.0939C5.95758 29.2892 5.22098 27.9432 5.22098 26.1124V9.74718H3.35019C2.52176 9.74718 1.85019 9.07561 1.85019 8.24718C1.85019 7.41876 2.52176 6.74718 3.35019 6.74718H9.94008ZM8.22098 9.74718H23.5918V26.1124C23.5918 26.4708 23.4616 26.9973 23.175 27.3953C22.9311 27.7341 22.5838 27.9831 21.9738 27.9831H10.7659C9.90384 27.9831 9.23995 27.839 8.83107 27.5746C8.53775 27.385 8.22098 27.0456 8.22098 26.1124V9.74718ZM12.7884 12.309C13.6168 12.309 14.2884 12.9806 14.2884 13.809V23.8371C14.2884 24.6655 13.6168 25.3371 12.7884 25.3371C11.96 25.3371 11.2884 24.6655 11.2884 23.8371V13.809C11.2884 12.9806 11.96 12.309 12.7884 12.309ZM20.6929 13.809C20.6929 12.9806 20.0213 12.309 19.1929 12.309C18.3645 12.309 17.6929 12.9806 17.6929 13.809V23.8371C17.6929 24.6655 18.3645 25.3371 19.1929 25.3371C20.0213 25.3371 20.6929 24.6655 20.6929 23.8371V13.809Z"
        fill="currentColor"
      />
    </svg>
  );
}
